<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand mr-5">
        <RouterLink to="/" class="navbar-item">
          <img src="../assets/logo-abaccus.svg" />
        </RouterLink>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <!-- <RouterLink
            to="/"
            class="navbar-item is-tab"
            :class="{ 'is-active': currentRouteName === 'principal' }"
          >
            <span> Principal </span>
          </RouterLink> -->

          <RouterLink
            to="/"
            class="navbar-item is-tab"
            :class="{ 'is-active': currentRouteName === 'licencas' }"
          >
            <span> Licenças </span>
          </RouterLink>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div
            class="dropdown is-right"
            :class="{ 'is-active': settingsActive }"
            v-on-click-outside="fechaDropdown"
          >
            <div class="dropdown-trigger">
              <button
                class="button is-rounded"
                @click="settingsActive = !settingsActive"
              >
                <span class="icon">
                  <i
                    class="fa-solid fa-circle-user"
                    style="font-size: x-large"
                  ></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div href="#" class="dropdown-item">
                  <div
                    class="is-flex is-flex-direction-column is-align-items-center"
                  >
                    <span class="icon">
                      <i
                        class="fa-solid fa-user"
                        style="font-size: xx-large"
                      ></i>
                    </span>
                    <span class="mt-3">Logado como:</span>
                    <span>usuario@dominio</span>
                  </div>
                </div>
                <hr class="dropdown-divider" />
                <a href="#" class="dropdown-item"> Gerenciar Conta </a>
                <div class="dropdown-item">
                  <div class="field">
                    <input
                      id="switchExample"
                      type="checkbox" 
                      name="switchExample"
                      class="switch is-rounded is-small is-rtl"
                      :checked="isDark"
                      @click="toggleDark()"
                    />
                    <label for="switchExample"> Modo Escuro </label>
                  </div>
                </div>

                <a href="#" class="dropdown-item" @click="fechaDropdown">
                  Fechar</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { vOnClickOutside } from '@vueuse/components';
  import { useDark, useToggle } from '@vueuse/core';

  const isDark = useDark();
  const toggleDark = useToggle(isDark);

  const route = useRoute();
  const currentRouteName = computed(() => route.name);
  const settingsActive = ref(false);

  function fechaDropdown() {
    settingsActive.value = false;
  }
</script>
<style lang="scss">
  @use '../assets/main';

  .navbar {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
  .switch[type='checkbox'].is-small + label {
    font-size: inherit !important;
  }
  .dropdown-menu {
    animation: dropdownOpenAnimation 0.2s ease-in-out;
  }

  @keyframes dropdownOpenAnimation {
    0% {
      display: none;
      opacity: 0;
      max-height: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      opacity: 1;
      max-height: 396px;
    }
  }
</style>
