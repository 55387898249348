<template>
  <div class="is-flex is-flex-direction-row is-justify-content-flex-end">
    <label for="itens-pagina">Itens por Página</label>
    <div class="select is-small ml-2">
      <select
        v-model="itensPagina"
        @change="mudouItensPorPagina"
        name="itens-pagina"
        id="itens-pagina"
      >
        <option :value="10">10</option>
        <option :value="20" :disabled="props.lista.length <= 10">20</option>
        <option :vauel="50" :disabled="props.lista.length <= 20">50</option>
      </select>
    </div>
    <span class="mx-4">
      {{ indiceInicial + 1 }} - {{ indiceFinal }} de
      {{ props.lista.length }}
    </span>
    <button
      class="button is-small has-text-grey"
      title="Primeira Página"
      :disabled="isPrimeiraPagina"
      @click="primeiraPagina"
    >
      <i class="fa-solid fa-angles-left"></i>
    </button>
    <button
      class="button is-small has-text-grey"
      title="Página Anterior"
      :disabled="isPrimeiraPagina"
      @click="paginaAnterior"
    >
      <i class="fa-solid fa-angle-left"></i>
    </button>
    <button
      class="button is-small has-text-grey"
      title="Próxima Página"
      :disabled="isUltimaPagina"
      @click="proximaPagina"
    >
      <i class="fa-solid fa-angle-right"></i>
    </button>
    <button
      class="button is-small has-text-grey"
      title="Última Página"
      :disabled="isUltimaPagina"
      @click="ultimaPagina"
    >
      <i class="fa-solid fa-angles-right"></i>
    </button>
  </div>
</template>
<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';

  const props = defineProps<{ lista: [] }>();
  const emit = defineEmits<{ paginate: [lista: []] }>();
  defineExpose({ primeiraPagina });

  const itensPagina = ref(10);
  const paginaAtual = ref(0);
  const isPrimeiraPagina = computed(() => paginaAtual.value === 0);
  const isUltimaPagina = computed(() => {
    const qtdPaginas = Math.ceil(props.lista.length / itensPagina.value);
    return paginaAtual.value + 1 === qtdPaginas;
  });
  const indiceInicial = computed(() => paginaAtual.value * itensPagina.value);
  const indiceFinal = computed(() => {
    const ultimoCalculado = indiceInicial.value + itensPagina.value;
    if (props.lista.length < ultimoCalculado) {
      return props.lista.length;
    }

    return ultimoCalculado;
  });
  const ultimaPaginaPossivel = computed(() => arrayPaginado.length - 1);

  let arrayPaginado: [[]] = [[]];

  watch(props, () => {
    transformaArrayPaginado();
    primeiraPagina();
  });
  function transformaArrayPaginado() {
    arrayPaginado = [[]];
    let subArray: [] = [];
    for (let index = 0; index < props.lista.length; index++) {
      if (index !== 0 && index % itensPagina.value === 0) {
        if (arrayPaginado.length === 1) {
          arrayPaginado[0] = [...subArray];
        } else {
          arrayPaginado.push([...subArray]);
        }
        subArray = [];
      }
      subArray.push(props.lista[index]);
      if (index + 1 === props.lista.length) {
        if (!arrayPaginado[0].length) {
          arrayPaginado[0] = [...subArray];
        } else {
          arrayPaginado.push([...subArray]);
        }
      }
    }
  }

  function emitir() {
    emit('paginate', arrayPaginado[paginaAtual.value]);
  }

  function primeiraPagina() {
    paginaAtual.value = 0;
    emitir();
  }

  function proximaPagina() {
    paginaAtual.value++;
    emitir();
  }

  function paginaAnterior() {
    paginaAtual.value--;
    emitir();
  }

  function ultimaPagina() {
    paginaAtual.value = ultimaPaginaPossivel.value;
    emitir();
  }
  function mudouItensPorPagina() {
    transformaArrayPaginado();
    primeiraPagina();
  }
</script>
