import { InjectionKey } from 'vue';
import { Store, useStore as baseUseStore, createStore } from 'vuex';

export interface State {
  isLoading: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    isLoading: false,
  },
  mutations: {
    showLoading(state) {
      state.isLoading = true;
    },
    hideLoading(state) {
      state.isLoading = false;
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
