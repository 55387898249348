import LicencasView from '@/views/LicencasView.vue';
import PrincipalView from '@/views/PrincipalView.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'principal',
  //   component: PrincipalView,
  // },
  {
    path: '/',
    name: 'licencas',
    component: LicencasView,
    // // route level code-splitting
    // // this generates a separate chunk (licencas.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "licenca" */ "../views/LicencasView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
