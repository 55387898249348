<template>
  <div id="loading" v-if="isLoading">
    <img src="../assets/spin.svg" alt="imagem de carregamento para aguardar" />
  </div>
</template>
<script lang="ts" setup>
  import { useStore } from '@/store';
  import { computed } from 'vue';

  const store = useStore();
  const isLoading = computed(() => store.state.isLoading);
</script>
<style>
  #loading {
    position: absolute;
    z-index: 1000;
    background-color: #7e7e7e8e;
    height: 100vh;
    width: 100vw;
    text-align: center;
    line-height: 100vh;
  }
</style>
