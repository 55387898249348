<template>
  <div class="modal" :class="{ 'is-active': isRevealed }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title"></slot>
        </p>
        <button class="delete" aria-label="close" @click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <slot name="content"></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useConfirmDialog } from '@vueuse/core';
  const { isRevealed, reveal, confirm, cancel, onReveal, onConfirm, onCancel } =
    useConfirmDialog();

  defineExpose({ reveal, confirm, cancel });
  const emit = defineEmits(['onReveal', 'onConfirm', 'onCancel']);

  onReveal(() => emit('onReveal'));
  onConfirm(() => emit('onConfirm'));
  onCancel(() => emit('onCancel'));
</script>
