<template>
  <Bar :options="(chartOptions as ChartOptions)" :data="chartData" />
</template>
<script setup lang="ts">
  import ILicenca from '@/interfaces/ILicenca';
  import { useDark } from '@vueuse/core';
  import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from 'chart.js';
  import { Ref, onMounted, ref } from 'vue';
  import { Bar } from 'vue-chartjs';

  const isDark = useDark();
  const props = defineProps<{ licencaSelecionada: ILicenca | null }>();
  let chartData: Ref<{ labels: any[]; datasets: any[] }> = ref({
    labels: [],
    datasets: [],
  });
  const chartOptions: Ref<any> = ref({
    responsive: true,
    scales: {
      y: {
        type: 'linear',
        ticks: { stepSize: 1 },
      },
    },
  });

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  );
  onMounted(() => {
    if (isDark.value) {
      ChartJS.defaults.borderColor = 'hsl(0, 0%, 81%)';
      ChartJS.defaults.backgroundColor = 'hsl(0, 0%, 81%)';
      ChartJS.defaults.color = 'hsl(0, 0%, 93%)';
    } else {
      ChartJS.defaults.borderColor = 'rgba(0,0,0,0.1)';
      ChartJS.defaults.backgroundColor = 'rgba(0, 0, 0, 0.1)';
      ChartJS.defaults.color = '#666';
    }
    popularGrafico();
  });

  function popularGrafico() {
    chartData.value = { labels: [], datasets: [] };
    let dataVerticais: any[] = [];
    let dataContextos: any[] = [];
    Object.entries(props.licencaSelecionada?.objetos).forEach(
      ([key, value]) => {
        const encontrado =
          props.licencaSelecionada?.instanciasFingerPrint.findLast(lic => {
            return lic.fingerprint === key;
          });

        if (encontrado) {
          chartData.value?.labels.push(parseStamp(encontrado.stamp));
          dataVerticais.push((value as any).totalVertical);
          dataContextos.push(
            (value as any).totalContexto
              ? Object.values((value as any).totalContexto).reduce(
                  (accumulator: number, value: any) => accumulator + value,
                  0
                )
              : 0
          );
        }
      }
    );
    chartData.value.datasets.push(
      {
        label: 'Verticais',
        data: dataVerticais,
        backgroundColor: isDark.value
          ? 'rgb(26, 156, 202)'
          : 'rgba(26, 156, 202, 0.4)',
        borderColor: 'rgb(26, 156, 202)',
        borderWidth: isDark.value ? 0 : 1,
      },
      {
        label: 'Contextos',
        data: dataContextos,
        backgroundColor: isDark.value
          ? 'rgb(255, 99, 132)'
          : 'rgba(255, 99, 132, 0.4)',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: isDark.value ? 0 : 1,
      }
    );
  }

  function parseStamp(stamp: string): string {
    const somenteData = stamp.substring(0, 8);
    return `${somenteData.substring(6, 8)}/${somenteData.substring(
      4,
      6
    )}/${somenteData.substring(0, 4)}`;
  }
</script>
