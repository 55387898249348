<template>
  <div class="field is-align-self-flex-start mt-2" v-if="licencas?.length">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Filtrar por empresa"
        v-model="filtro"
        @keyup="filtrar"
      />
    </div>
  </div>
  <div
    v-if="!licencas?.length"
    class="is-flex is-flex-direction-column is-align-items-center mt-6"
  >
    <span class="icon has-text-grey-lighter">
      <i class="fa-solid fa-skull-crossbones fa-beat icone-alerta"></i>
    </span>
    <h3 class="mt-4">Não existem licenças para listar</h3>
  </div>
  <table class="table is-hoverable" v-if="licencas?.length">
    <thead>
      <tr>
        <th @click="ordenar('empresa')" title="clique para ordenar">
          Empresa
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.empresa === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.empresa === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('verticais')">
          Verticais
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.verticais === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.verticais === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('aplicacoes')">
          Aplicações
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.aplicacoes === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.aplicacoes === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('contextoExecucao')">
          Contextos de Execução
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.contextoExecucao === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.contextoExecucao === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('revogado')">
          Status
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.revogado === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.revogado === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('motorConcorrente')">
          Motores Concorrentes
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.motorConcorrente === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.motorConcorrente === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
        <th @click="ordenar('genConcorrente')">
          Binários Concorrentes
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.genConcorrente === 1"
          >
            <i class="fa-solid fa-chevron-down"></i>
          </span>
          <span
            class="icon has-text-grey-light"
            v-if="tipoOrdenacao.genConcorrente === 2"
          >
            <i class="fa-solid fa-chevron-up"></i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="licenca in (licencasPaginadas as ILicenca[])"
        :key="licenca.uuid"
        @click="abrirDetalhes(licenca)"
        title="Clique para ver os detalhes da licença"
      >
        <td width="30%">{{ licenca.empresa }}</td>
        <td width="10%">{{ licenca.verticais }}</td>
        <td width="10%">{{ licenca.aplicacoes }}</td>
        <td width="10%">{{ licenca.contextoExecucao }}</td>
        <td width="10%">
          <span
            class="icon"
            :class="{
              'has-text-success': licenca.revogado === 'N',
              'has-text-danger': licenca.revogado === 'S',
            }"
          >
            <i class="fa-solid fa-circle"></i>
          </span>
          {{ licenca.revogado === 'N' ? 'Online' : 'Offline' }}
        </td>
        <td width="15%">{{ licenca.motorConcorrente }}</td>
        <td width="15%">{{ licenca.genConcorrente }}</td>
      </tr>
    </tbody>
  </table>
  <PaginatorComp
    :lista="(licencasFiltradasEOrdenadas as [])"
    @paginate="paginou"
  ></PaginatorComp>

  <ModalCard ref="modal" @on-cancel="mudaAba('atual')">
    <template #title> {{ licencaSelecionada?.empresa }} </template>
    <template #content>
      <div class="tabs is-toggle is-toggle-rounded is-centered is-small">
        <ul>
          <li
            :class="{ 'is-active': abaAtiva === 'atual' }"
            @click="mudaAba('atual')"
          >
            <a>
              <span class="icon is-small"
                ><i class="fa-regular fa-eye"></i
              ></span>
              <span>Visão Atual</span>
            </a>
          </li>
          <li
            :class="{ 'is-active': abaAtiva === 'historico' }"
            @click="mudaAba('historico')"
          >
            <a>
              <span class="icon is-small"
                ><i class="fa-solid fa-chart-simple"></i
              ></span>
              <span>Histórico</span>
            </a>
          </li>
        </ul>
      </div>

      <DetalheLicenca
        v-if="abaAtiva === 'atual'"
        :licenca="licencaSelecionada"
        :ultimo-objeto="ultimoObjeto"
      ></DetalheLicenca>
      <div v-else>
        <HistoricoLicenca
          :licenca-selecionada="licencaSelecionada"
        ></HistoricoLicenca>
      </div>
    </template>
    <!-- <template #footer>
      <button class="button" disabled>Editar</button>
      <button class="button is-danger" disabled>Excluir</button>
    </template> -->
  </ModalCard>
</template>
<script lang="ts" setup>
  import ILicenca from '@/interfaces/ILicenca';
  import { Ref, onMounted, ref } from 'vue';
  import ModalCard from './ModalCard.vue';
  import PaginatorComp from './PaginatorComp.vue';

  import DetalheLicenca from './DetalheLicenca.vue';
  import HistoricoLicenca from './HistoricoLicenca.vue';

  interface ITipoOrdenacao {
    empresa: number;
    aplicacoes: number;
    verticais: number;
    contextoExecucao: number;
    revogado: number;
    motorConcorrente: number;
    genConcorrente: number;
  }

  const props = defineProps<{
    licencas: ILicenca[];
  }>();

  const filtro = ref('');
  const licencaSelecionada: Ref<ILicenca | null> = ref(null);
  const ultimoObjeto: Ref<any> = ref({
    id: '',
    totalVertical: 0,
    totalContexto: 0,
    totalVariavel: 0,
  });
  const licencasFiltradasEOrdenadas: Ref<ILicenca[]> = ref([]);
  const licencasPaginadas: Ref<[]> = ref([]);
  const tipoOrdenacao: Ref<ITipoOrdenacao> = ref({
    empresa: 0,
    aplicacoes: 0,
    verticais: 0,
    contextoExecucao: 0,
    revogado: 0,
    motorConcorrente: 0,
    genConcorrente: 0,
  });
  const modal = ref<InstanceType<typeof ModalCard> | null>(null);
  const abaAtiva: Ref<'atual' | 'historico'> = ref('atual');

  onMounted(() => {
    licencasFiltradasEOrdenadas.value = [...props.licencas];
  });

  function abrirDetalhes(licenca: ILicenca) {
    licencaSelecionada.value = licenca;
    const ultimo: any = Object.values(licencaSelecionada.value.objetos).at(-1);
    if (ultimo) {
      let totalContexto = ultimo.totalContexto
        ? Object.values(ultimo.totalContexto).reduce(
            (accumulator: number, value: any) => accumulator + value,
            0
          )
        : 0;
      console.log(Object.entries(licencaSelecionada.value.objetos));
      ultimoObjeto.value = {
        id: Object.keys(licencaSelecionada.value.objetos).at(-1),
        totalVertical: ultimo.totalVertical ? ultimo.totalVertical : 0,
        totalContexto,
      };
    } else {
      ultimoObjeto.value = {
        id: '',
        totalVertical: 0,
        totalContexto: 0,
        totalVariavel: 0,
      };
    }
    modal.value?.reveal();
  }

  function ordenar(
    tipo:
      | 'empresa'
      | 'verticais'
      | 'aplicacoes'
      | 'contextoExecucao'
      | 'revogado'
      | 'genConcorrente'
      | 'motorConcorrente'
  ) {
    if (tipoOrdenacao.value[tipo] === 2) {
      tipoOrdenacao.value[tipo] = 0;
    } else {
      tipoOrdenacao.value[tipo]++;
    }

    switch (tipoOrdenacao.value[tipo]) {
      case 0:
        licencasFiltradasEOrdenadas.value = [...props.licencas];
        break;
      case 1:
        licencasFiltradasEOrdenadas.value =
          licencasFiltradasEOrdenadas.value.sort((a: ILicenca, b: ILicenca) => {
            if (tipo === 'empresa') {
              return a.empresa.toLowerCase() > b.empresa.toLocaleLowerCase()
                ? 1
                : -1;
            } else {
              return a[tipo] > b[tipo] ? -1 : 1;
            }
          });
        break;
      case 2:
        licencasFiltradasEOrdenadas.value =
          licencasFiltradasEOrdenadas.value.sort((a: ILicenca, b: ILicenca) => {
            if (tipo === 'empresa') {
              return a.empresa.toLocaleLowerCase() <
                b.empresa.toLocaleLowerCase()
                ? 1
                : -1;
            } else {
              return a[tipo] < b[tipo] ? -1 : 1;
            }
          });
        break;
      default:
        break;
    }

    Object.keys(tipoOrdenacao.value).forEach(key => {
      if (key !== tipo) {
        tipoOrdenacao.value[key as keyof ITipoOrdenacao] = 0;
      }
    });
  }
  function filtrar() {
    tipoOrdenacao.value = {
      empresa: 0,
      aplicacoes: 0,
      verticais: 0,
      contextoExecucao: 0,
      revogado: 0,
      motorConcorrente: 0,
      genConcorrente: 0,
    };
    if (!filtro.value) {
      licencasFiltradasEOrdenadas.value = [...props.licencas];
    }
    licencasFiltradasEOrdenadas.value = props.licencas?.filter(
      (licenca: ILicenca) =>
        licenca.empresa.toUpperCase().includes(filtro.value.toUpperCase())
    );
  }

  function paginou(listaPaginada: []) {
    licencasPaginadas.value = listaPaginada;
  }

  function mudaAba(aba: 'atual' | 'historico') {
    abaAtiva.value = aba;
  }
</script>
<style>
  th {
    cursor: pointer;
    user-select: none;
  }

  tr {
    cursor: pointer;
  }

  .acoes {
    cursor: initial;
  }

  .progress {
    margin: 8px 0 !important;
  }

  small {
    font-size: 0.8rem !important;
  }
</style>
